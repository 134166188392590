.nomeusuario { 
    position: 'relative' 
}
.boxnome{
    position: 'absolute';
    top: 'calc(100% + 5px)';
    left: 0;
    width: '100%';
    background-color: 'white';
    border: '1px solid #ccc';
    border-radius: '4px';
    z-index: 1;
}
