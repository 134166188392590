.cardestab{
  width: 18rem;
}

.card-img-top {
    width: 100px;
    align-self: center;
}

.custom-card {
  width: 18rem;
  height: 200px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  margin: 0 auto; /* Adicionado para centralizar */
}


.custom-card-header {
  background-color: #ffffff;
}

.custom-card-body {
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.participante {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  background-color: #fff;
}

.participante img {
  max-width: 100%;
  height: auto;
}

.participante .logo {
  max-width: 50%;
  margin-top: 10px;
}


.custom-card-footer {
  background-color: #f1f1f1;
  padding: 5px;
  text-align: center;
}

.shadow-image {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px; 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.shadow-image:hover {
  transform: scale(1.05); 
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}
