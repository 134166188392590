.sorteio-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .numero-sorteado {
    font-size: 48px;
    margin-bottom: 20px;
    transition: transform 2s ease-out;
  }
  
  .sorteando {
    animation: sorteio-animation 2s ease-in-out infinite;
  }
  
  @keyframes sorteio-animation {
    0%, 100% {
      transform: rotateY(0deg);
    }
    25% {
      transform: rotateY(180deg);
    }
    50% {
      transform: rotateY(360deg);
    }
    75% {
      transform: rotateY(180deg);
    }
  }

  
  