.grafico {
    padding-left: 10%;
    padding-right: 10%;
}

.identificacao {
    margin: auto;
    width: 50%;
    border: 3px solid rgb(12, 60, 82);
    padding: 10px; 
}