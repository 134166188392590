.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    overflow-y: auto;
    z-index: 99999;
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 60%;
    height: fit-content;
    max-height: 100%;
    background-color: #fff;    
}

.popup-inner .close-brn {
    position: absolute;
    top: 16px;
    right: 16px;

}

