/* Estilo geral do contêiner da notificação */
.notf-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
  }
  
  /* Efeito hover */
  .notf-container:hover {
    background-color: #f1f1f1;
  }
  
  /* Estilo do contêiner da imagem */
  .notf-image-container {
    flex: 0 0 80px;
    margin-right: 10px;
  }
  
  /* Estilo da imagem */
  .notf-image {
    width: 120px;
    height:120px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  /* Estilo do conteúdo (título e corpo) */
  .notf-content {
    flex: 1;
  }
  
  /* Estilo do título */
  .notf-content h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  /* Estilo do corpo do texto */
  .notf-content p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #555;
  }
  
  /* Estilo do rodapé (data) */
  .notf-footer {
    margin-top: 10px;
    font-size: 12px;
    color: #999;
  }

  .notf-actions {
    flex: 0 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  