.form-estabelecimento {
  
}
.form-estabelecimento select {
  width: 100%;
  border: 0px;
  background-color: #fff;
  height: 30px;
  object-position: left;
  cursor: pointer;
}