 .tabs{
   min-height: 500px;
 }
 
 .tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
  }
  
  .tab-list-active {
    background-color: #0086b5;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    color: white;
  }