.form-login{
    width: 330px;
    padding: 15px;
    background-color: rgb(255, 255, 255);
}

.form-login input{
    margin-bottom: 10px;
    
}

.background-login{
  object-fit: cover;  
  width: 88%;
  height: 100hv;
  object-position: left;
}
.novocadastro{
  color: white;
  background-color: rgb(25, 135, 124);
}

.esqueceusenha{
  color: rgb(54, 5, 5);
  background-color: rgb(228, 149, 149);
}