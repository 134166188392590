@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body{
    overflow-x: hidden;    
    font-family: 'Poppins';       
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/*body {
   background: linear-gradient(to bottom, #6d8783, #a1beb9);

}*/


body::-webkit-scrollbar{
    display: none;
}

.mt-page{
    margin-top: 150px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 100px;
}
.mt-page h1{
    color: rgb(0,55,108) !important;

}

.react-modal-overlay {
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;

}

.react-modal-content{
    width: 100%;
    max-width: 80%;
    background: #fff;
    padding: 1rem;
    position: relative;
    border-radius: 0.24rem;
    margin-left: 15px;
    margin-right: 15px;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;    
}

.react-modal-close{
    position: absolute;
    right: -20px;
    top: -15px;
    border: 0;
    background: transparent;
}

.react-modal-close-left{
    position: absolute;
    right: 340px;
    top: 0px;
    border: 0;
    background: transparent;
}
.btn-danger {
    background-color: rgb(0,55,108) !important;
  }