.radio-control{
    width: 100%;
    background-color: #fff;
    height:55%;
    object-position: left;
    cursor: pointer;
}

.form-check{
    margin-bottom: 0%;
}

.form-check-input {
    height: 16px;
    margin-bottom: 0px;   
}