.form-solicita{
    width: 330px;
    padding: 15px;
    background-color: rgb(171, 190, 220);
}

.form-solicita input{
    margin-bottom: 10px;
    
}

.background-solicita{
  object-fit: cover;  
  width: 88%;
  height: 100hv;
  object-position: left;
}