.toolbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .toolbar-button {
    margin-right: 10px;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toolbar-button:hover {
    background-color: #0056b3;
  }

  .toolbar-refresh {
    margin-right: 10px;
    padding: 10px 15px;
    background-color: #367e49;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toolbar-refresh:hover {
    background-color: #367e49;
  }
  