.card-header{
    background-color: rgb(0,55,108) !important;
    color: aliceblue;
}

.card-body{
    font-size: large;
}

.card-title{
    font-weight: bold;
}

.card-text{
    font-size: large;
    color: rgb(0,55,108) !important;;
}