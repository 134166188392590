.block-info{
    width: 50%;
    height: fit-content;
}

.card-ola {
    width: 92%;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 25px;
  }

  .card-ola h4 {
    margin-left: 10px;
  }
  .card-ola-title {
    margin-left: 10px;
    font-size: 1.0em;
  }

  .card-ola-subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    color: #555;
    margin-right: 10px;
    margin-left: 10px;
  }

  .cupons {    
    text-align: left;
  }
  
  .saldo {    
    text-align: right;
  }