.linhas-card{
    margin-top: 25px;
    padding-left: 1%;
    padding-right: 1%;
}

.linhas2-card{
    font-size: smaller;
    align-content: center;
}

.linhas2-card span{
    font-size: smaller;
    align-content: center;
}

