.titulo-navbar{
    color: rgb(0,55,108) !important;
}
.bg-custom{
    background-color: white;
}

.nav-item {
    font-size: 24px;
}

.navbar-nav .nav-link {
    color: rgb(0,55,108) !important;
}
  
.navbar-nav .nav-link:hover {
    color:  rgb(0,55,108) !important;
}

.custom-toggler .navbar-toggler-icon {
    background-color: rgb(0, 55, 108); 
    border-radius: 4px;
  }
  
  .custom-toggler {
    border-color: rgb(0, 55, 108);
  }
  