.cupom-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 10px;
  }
  
.cupom {
    width: 300px;
    height: 150px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 10px;
  }
  
  .cupom {
    display: flex;
    flex-direction: column;
  }
 
  .cupom-images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  
  .banner-image {
    width: 100px;
  }
  
  .logo-image {
    width: 50px;
  }
   
  .cupom-title {
    margin: 0;
    font-size: 1.2em;
  }
  
  .cupom-subtitle {
    margin: 0;
    font-size: 0.9em;
    color: #555;
  }
  .cupom-estab {
    margin: 0;
    font-size: 0.9em;
    color: #555;
  }
  .cupom-content {
    padding: 10px;
    text-align: center;
  }

  