.container-cab {
    display: flex;
    justify-content: space-between; /* Alinha os elementos nas extremidades */
    padding-left: 0px;
  }
  
  .left-cab {    
    background-color:  #0086b5; /* Define a cor de fundo como azul */
    color: white; /* Define a cor do texto como branca */
    padding: 10px; /* Adiciona preenchimento interno para melhor aparência */
    display: inline-block;
  }
  
  .right-cab {
    padding-top: 5px;
    padding-right: 8px;
    text-align: right; /* Alinha o texto à direita */
  }