.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #ffffff; 
    border-top: 1px solid rgb(0,55,108) !important;
    padding: 1px 0; 
    height: 50px;
}

@media (min-height: 50vh) {
    .footer {
        position: fixed;
    }
}
